import {useEffect, useState} from "react";
import { getDoc, doc } from "firebase/firestore/lite"
import { profiles } from "../utils/firebase.utils";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {Phone, EnvelopeSimple, LinkedinLogo, Plus, FilePdf} from "phosphor-react";
import PdfPreview from "../assets/img.png";
import PdfPreview2 from "../assets/pdf_preview_portfolio.png";

class Profile {
    name: string
    email: string
    mobile: string
    image: string
    link_linkedin: string
    designation: string
    vcf_link: string

    constructor(data?: { [field: string]: any }) {
        this.name = data?.name ?? "";
        this.email = data?.email ?? "";
        this.mobile = data?.mobile ?? "";
        this.image = data?.image ?? "";
        this.link_linkedin = data?.link_linkedin ?? "";
        this.designation = data?.designation ?? "";
        this.vcf_link = data?.vcf_link ?? "";
    }
}

const ProfileDetails = () => {

    const { profile_id }: {profile_id?: string} = useParams()
    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState<Profile | null>(null)

    useEffect(() => {
        setLoading(true);
        setProfile(null);
        if (profile_id)
            getDoc(doc(profiles, profile_id))
                .then((snap) => {
                    let doc = snap.data();
                    if (doc) {
                        let t_profile = new Profile(doc)
                        setProfile(t_profile)
                        setLoading(false)
                    }
                })
                .catch(() => {
                })
    }, [profile_id])

    return (
        <>
            {!loading && (
                <Helmet>
                    <title>{profile.name} • VSI</title>
                </Helmet>
            )}

            <div className={"background_pattern absolute w-full h-full top-0 left-0 pointer-events-none"}/>
            
            
            <div className={"relative px-6 pt-6 pb-0 z-10"}>
                <div className={"h-10 z-10"}>
                    <svg id="VSI_LOGO" data-name="VSI_LOGO" xmlns="http://www.w3.org/2000/svg" className={"h-10 z-10"} viewBox="0 0 149.92 72.05" style={{ transition: "0.3s" }}>
                        <defs>
                            <linearGradient id={"green"} x1={0} y1={0} x2={1} y2={1}>
                                <stop offset={"0%"} stopColor={"#dedc00"} />
                                <stop offset={"80%"} stopColor={"#a6ca4d"} />
                            </linearGradient>

                            <linearGradient id={"red"} x1={0} y1={1} x2={1} y2={0}>
                                <stop offset={"0%"} stopColor={"#e9503a"} />
                                <stop offset={"80%"} stopColor={"#f9b43c"} />
                            </linearGradient>

                            <linearGradient id={"blue"} x1={1} y1={0} x2={0} y2={1}>
                                <stop offset={"0%"} stopColor={"#1467b3"} />
                                <stop offset={"80%"} stopColor={"#0098d9"} />
                            </linearGradient>

                            <linearGradient id={"pink"} x1={0} y1={0} x2={1} y2={1}>
                                <stop offset={"0%"} stopColor={"#963589"} />
                                <stop offset={"80%"} stopColor={"#ea4b8e"} />
                            </linearGradient>
                        </defs>
                        <g id="LOGO" data-name="LOGO">
                            <g id="PETALS" data-name="PETALS 5">
                                <path id="Rectangle_1" data-name="Rectangle 1"
                                    d="M212.12,402.16h10.71a11.42,11.42,0,0,1,11.42,11.42h0v10.71h-10.7a11.43,11.43,0,0,1-11.43-11.42h0Z"
                                    transform="translate(-212.12 -387.21)" style={{ transition: "0.3s", fill: "url(#green)" }}/>
                                <path id="Rectangle_2" data-name="Rectangle 2"
                                    d="M269.23,402.16v10.71a11.42,11.42,0,0,1-11.42,11.42H247.1V413.58a11.42,11.42,0,0,1,11.43-11.42h10.7Z"
                                    transform="translate(-212.12 -387.21)" style={{ transition: "0.3s", fill: "url(#red)" }}/>
                                <path id="Rectangle_3" data-name="Rectangle 3"
                                    d="M269.23,459.27h-10.7a11.43,11.43,0,0,1-11.43-11.42h0V437.14h10.71a11.42,11.42,0,0,1,11.42,11.42h0Z"
                                    transform="translate(-212.12 -387.21)" style={{ transition: "0.3s", fill: "url(#pink)" }}/>
                                <path id="Rectangle_4" data-name="Rectangle 4"
                                    d="M212.12,459.27V448.56a11.42,11.42,0,0,1,11.43-11.42h10.7v10.71a11.42,11.42,0,0,1-11.42,11.42H212.12Z"
                                    transform="translate(-212.12 -387.21)" style={{ transition: "0.3s", fill: "url(#blue)" }}/>
                            </g>
                            <g id="vsi" className="cls-6">
                                <g className="cls-6">
                                    <path
                                        d="M294.5,434.15l-11.05-32v-.62h8.67l7.54,24.37h.25l7.35-24.37h8.54v.62l-10.93,32Z"
                                        transform="translate(-212.12 -387.21)"
                                        style={{ transition: "0.3s", fill: "#0797d5" }}
                                    />
                                    <path
                                        d="M332.83,434.71q-6.91,0-10.49-3.11a11,11,0,0,1-3.71-8.45h8.42a4,4,0,0,0,1.51,3.21,6.47,6.47,0,0,0,4.27,1.25,7.71,7.71,0,0,0,4-.85,2.48,2.48,0,0,0,1.38-2.16c0-1.59-1.25-2.64-3.77-3.14l-5.27-1q-9.56-1.81-9.55-9.93a8.42,8.42,0,0,1,3.48-6.88q3.5-2.73,9.52-2.73t9.68,3a9.73,9.73,0,0,1,3.64,7.82h-8.29a3.75,3.75,0,0,0-1.35-3,5.65,5.65,0,0,0-3.8-1.17,6.22,6.22,0,0,0-3.4.82,2.46,2.46,0,0,0-1.25,2.14c0,1.5,1.11,2.49,3.32,3l6,1.07a14.51,14.51,0,0,1,7,3.14c1.7,1.51,2.54,3.77,2.54,6.78a8.59,8.59,0,0,1-3.89,7.48A17.17,17.17,0,0,1,332.83,434.71Z"
                                        transform="translate(-212.12 -387.21)"
                                        style={{ transition: "0.3s", fill: "#0797d5" }}
                                    />
                                    <path
                                        d="M356.83,397.39a4.67,4.67,0,0,1-5.22-5.15,5,5,0,0,1,1.32-3.67,6.17,6.17,0,0,1,7.73,0,4.89,4.89,0,0,1,1.38,3.67,5.08,5.08,0,0,1-1.38,3.8A5.25,5.25,0,0,1,356.83,397.39Zm-4.27,36.76V401.48H361v32.67Z"
                                        transform="translate(-212.12 -387.21)"
                                        style={{ transition: "0.3s", fill: "#0797d5" }}
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div style={{ transition: "0.3s", opacity: loading ? 0 : 1 }}>
                <div className={"space-y-6 p-6"}>
                    <div>
                        <p className={"relative font-bold text-5xl mb-1"}>
                            {profile?.name}
                        </p>
                        <p className={"relative text-lg opacity-70 tracking-wide"}>
                            {profile?.designation}
                        </p>
                    </div>
                    <div className={"relative aspect-w-5 aspect-h-5 w-full rounded-3xl"} style={{ background: `url(${profile?.image},ar-1-1,fo-auto) center center / cover` }}/>
                    <div>
                        <a className={"relative flex justify-center items-center w-full py-4 px-6 rounded-full transition duration-100 bg-zinc-900 hover:bg-blue-900 text-blue-200 tracking-wide"} href={profile?.vcf_link}>
                            <div className={"relative flex items-center"}>
                                <Plus weight={"bold"} size={18}  className={"absolute -left-7"}/>
                                <span className={"text-xl font-semibold"}> Add to contacts</span>
                            </div>
                        </a>
                    </div>
                    <div className={"bg-white rounded-2xl border  w-full relative overflow-hidden"}>
                        <a href={`tel:${profile?.mobile}`} className={"flex items-center px-4 py-2 space-x-4 border-b hover:bg-gray-50"}>
                            <Phone size={18} weight={"fill"}  className={"text-blue-500"}/>
                            <p className={"relative text-base opacity-90 tracking-wide"}>
                                {profile?.mobile}
                            </p>
                        </a>
                        <a href={`mailto:${profile?.email}`} className={"flex items-center px-4 py-2 space-x-4 border-b hover:bg-gray-50"}>
                            <EnvelopeSimple weight={"fill"} size={18}  className={"text-blue-500"}/>
                            <p className={"relative text-base opacity-90 tracking-wide"}>
                                {profile?.email}
                            </p>
                        </a>
                        {profile?.link_linkedin && (
                            <a target={"_blank"} rel="noreferrer" href={`${profile?.link_linkedin}`} className={"flex items-center px-4 py-2 space-x-4 hover:bg-gray-50"}>
                                <LinkedinLogo weight={"fill"} size={18} className={"text-blue-500"}/>
                                <p className={"relative text-base opacity-90 tracking-wide"}>
                                    LinkedIn
                                </p>
                            </a>
                        )}

                    </div>
                    <div/>
                    <a href={`/vsi_company_profile_web_1.pdf`} className={"block md:hidden"} target={"_blank"}>
                        <div className={"bg-white rounded-2xl border w-full relative overflow-hidden"}>
                            <div className={"relative aspect-w-5 aspect-h-2 w-full rounded-2xl"}style={{background: `url(${PdfPreview}) center center / cover`}}/>
                            <div  className={"flex items-center px-4 py-2 space-x-4 border-b hover:bg-gray-50"}>
                                <FilePdf size={18} weight={"fill"} className={"text-blue-500"}/>
                                <p className={"relative text-base opacity-90 tracking-wide"}>
                                    View Company Profile
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href={`/vsi_company_profile_web_spreads.pdf`} className={"hidden md:block"} target={"_blank"}>
                        <div className={"bg-white rounded-2xl border w-full relative overflow-hidden"}>
                            <div className={"relative aspect-w-5 aspect-h-2 w-full rounded-2xl"}style={{background: `url(${PdfPreview}) center center / cover`}}/>
                            <div  className={"flex items-center px-4 py-2 space-x-4 border-b hover:bg-gray-50"}>
                                <FilePdf size={18} weight={"fill"} className={"text-blue-500"}/>
                                <p className={"relative text-base opacity-90 tracking-wide"}>
                                    View Company Profile
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href={`/vsi_dossier_list.pdf`} className={"block"} target={"_blank"}>
                        <div className={"bg-white rounded-2xl border w-full relative overflow-hidden"}>
                            <div className={"relative aspect-w-5 aspect-h-2 w-full rounded-2xl"}style={{background: `url(${PdfPreview2}) center center / cover`}}/>
                            <div  className={"flex items-center px-4 py-2 space-x-4 border-b hover:bg-gray-50"}>
                                <FilePdf size={18} weight={"fill"} className={"text-blue-500"}/>
                                <p className={"relative text-base opacity-90 tracking-wide"}>
                                    View Product Portfolio
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )

}

export default ProfileDetails;