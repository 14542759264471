import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: "AIzaSyCoGwigKF7oqWeij209V-WGEg107s572iE",
    authDomain: "vs-intl.firebaseapp.com",
    projectId: "vs-intl",
    storageBucket: "vs-intl.appspot.com",
    messagingSenderId: "32930852223",
    appId: "1:32930852223:web:43310fe2917f57922a3e4a",
    measurementId: "G-HK00D0FSSQ"
};

const firebaseApp = initializeApp(firebaseConfig);


// Firebase Instances
export const firestore = getFirestore(firebaseApp);

// Collections
export const profiles = collection(firestore, "profiles");


