import React from 'react'
import './index.css'
import App from './app'
import {BrowserRouter, Route, Routes} from "react-router-dom"
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<App/>}/>
            </Routes>
        </BrowserRouter>
    </>
);