import React from 'react';
import Favicon from "react-favicon";
import FaviconImage from "./assets/favicon.png";
import { Routes, Route } from "react-router-dom"
import ProfileDetails from "./pages/profile-details";

const App = () => {

    return (
        <>
            <Favicon url={FaviconImage}/>
            <main className={"max-w-md mx-auto"}>
                <Routes>
                    <Route path='/:profile_id' element={<ProfileDetails/>} />
                </Routes>
            </main>
        </>
    );
}

export default App;